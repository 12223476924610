import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AdminPopupModule } from '../admin-popup/admin-popup.module';

import { ProfilePopupModule } from '../profile-popup/profile-popup.module';


import { ToolbarComponent } from './toolbar.component';

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    CommonModule, IonicModule, RouterModule, TranslateModule,
    ProfilePopupModule,AdminPopupModule
  ],
  exports: [ToolbarComponent]
})
export class ToolbarModule {
}
