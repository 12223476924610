import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { SettingsService } from './settings.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private settingsService: SettingsService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // // check if route is restricted by role
            // if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
            //     // role not authorised so redirect to home page
            //     this.router.navigate(['/']);
            //     return false;
            // }

            // authorised so return true
            return true;
        } else {
            console.log('get identity');
            return this.authenticationService.identity().pipe(map((e) => {
                if (e) {
                    return true;
                } else {
                    this.settingsService.storeLastUrl(state.url);
                    this.router.navigate(['/login']);
                    return false;
                }
            }),
                catchError(() => {
                    this.settingsService.storeLastUrl(state.url);
                    this.router.navigate(['/login']);
                    return of(false);
                }));
        }
    }
}
