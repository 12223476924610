import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SettingsService } from './settings.service';


@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private settingsService: SettingsService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    if (!request || !request.url) {
      return next.handle(request);
    }

    const authRequest = [
      '/currentuser',
      '/getuserprofile',
      '/getuserprofile/',
      '/updateuserprofile',
      '/get_services',
      '/get_logs',
      '/get_log_types',
      '/json_to_csv',
      '/verify_user_subscription/',
      '/unsubscribe/',
      '/json_to_xlsx',
      `/subscribe/${environment.paypal.product}`,
      '/buycredits',
      '/analytics',
      '/approval_pending',
      '/approval_pending_total',
      '/approve_pending',
      '/get_subscriber',
      '/change_pwd',
      '/post_message',
      '/get_messages',
      '/set_message_read',
      '/delete_message',
      '/post_feedback',
      '/checkVatService/',
      '/latestRates/',
      '/geocoding/',
      '/fcst/',
      '/cap/',
      
    ];

    let relativeUrl = request.url
      .replace(`${environment.baseUrl}`, '');
    const index = relativeUrl.indexOf('?');
    if (index >= 0) {
      relativeUrl = relativeUrl.substr(0, index);
    }
    if (authRequest.indexOf(relativeUrl) >= 0) {
      const token = this.settingsService.getToken();
      if (token) {
        request = request.clone({ setHeaders: { 'x-access-token': token } });
      }
    }
    return next.handle(request);
  }
}
