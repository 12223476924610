import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-cookie-law',
  templateUrl: './cookie-law.component.html',
  styleUrls: ['./cookie-law.component.scss']
})
export class CookieLawComponent implements OnInit {
  accepted = false;

  constructor(
    private platform: Platform,
    private settingService: SettingsService) { }

  ngOnInit() {
    if (this.platform.is('hybrid')) {
      this.accepted = true;
    } else {
      this.accepted = this.settingService.getCookieLow();
    }

  }
  dismissClick() {
    this.accepted = true;
    this.settingService.acceptCookieLow()
  }
  goToDocs() { }
}
