import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { DarkThemeStatus } from './dark-theme.service';
import { SettingsService } from './settings.service';



@Injectable({ providedIn: 'root' })
export class ResolveTheme implements Resolve<string> {
  constructor(private settingsService: SettingsService) { }

  // resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   const cssClass = this.settingsService.getDarkTheme() === DarkThemeStatus.DO_NOT_USE ? '' : 'dark';
  //   document.body.className = cssClass;
  //   return of(null);
  // }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const cssClass = this.settingsService.getDarkTheme() === DarkThemeStatus.USE_DARK_THEME ? 'dark' : '';
    document.body.className = cssClass;
    return of(null);
  }
  
}

