import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { errorRoute } from './pages/public/error/error.route';
import { AuthGuard } from './services/auth.guard';
import { ResolveTheme } from './services/theme.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'public',
    pathMatch: 'full'
  },
  {
    path: 'public',
    resolve: { theme: ResolveTheme },
    loadChildren: () => import('./pages/public/public-routing.module').then(m => m.PublicPageRoutingModule)
  },
  {
    path: 'login',
    redirectTo: 'public/login',
    pathMatch: 'full'
  },
  {
    path: 'private',
    resolve: { theme: ResolveTheme },
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/private/private-routing.module').then(m => m.PrivatePageRoutingModule)
  },
 

];

@NgModule({
  imports: [
    RouterModule.forRoot([...routes, ...errorRoute], { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
