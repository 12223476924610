/* eslint-disable @angular-eslint/no-input-rename */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuController, PopoverController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ProfilePopupComponent } from '../profile-popup/profile-popup.component';
import { AdminPopupComponent } from '../admin-popup/admin-popup.component';
import { IUser } from 'src/app/models/user.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import {ApiService} from 'src/app/services/api.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit,
  OnDestroy {
  @Input('color') color: string;
  @Input('back') back = false;
  @Input('menu') menu = false;
  @Input('title') title: string;
  @Input('scrollArea') scrollArea;
  @Input('backUrl') backUrl: string;
  @Input('translucent') translucent = false;
  user$: Observable<IUser>;
  unsubscribe$ = new Subject<void>();
  email$: Observable<string>;
  balance$: Observable<string>;
  role$:Observable<number>;
  hidePrice = false;
  pendings$: any;
  total_pending:any;
  total_subscribers: any;
  role: any =''
  messages$: any;
  total_messages: any;
  backend_version: any;



  constructor(
    private authenticationService: AuthenticationService,
    private apiservice: ApiService,
    private menuController: MenuController,
    public popoverController: PopoverController,
    private $localStorage: LocalStorageService) { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  openMenu() {
    this.menuController.enable(true, 'mainMenu');
    this.menuController.open('mainMenu');
  }

  async ngOnInit() {
    // this.role=''
    this.user$ = this.authenticationService.currentUser.pipe(takeUntil(this.unsubscribe$));
    this.email$ = this.authenticationService.currentUser.pipe(map(user => user?.email));
    this.balance$ = this.authenticationService.currentUser.pipe(map(user => user?.credits));
    this.role$ = this.authenticationService.currentUser.pipe(map(user => user?.role));
    this.role$.subscribe(val => (this.role=val))
    // this.user$.subscribe(val =>{this.role=val.role; console.log('subscribe user$: ', val)})
    // this.unsubscribe$.subscribe(val =>{console.log(val)})
    
    
    if (this.role==1){

      // console.log('valore ruolo attuale dentro IF: '+ this.role)

      this.pendings$ = await this.apiservice.getPending_total().toPromise();
      this.total_pending=this.pendings$.result.total_pending
      this.total_subscribers=this.pendings$.result.total_subscribers
      this.backend_version=this.pendings$.result.backend_version
      this.messages$ = await this.apiservice.get_messages().toPromise();
      this.total_messages= this.messages$.meta.total_messages
      // console.log(this.messages$)
      // console.log(this.total_messages)

    } else {
      this.total_pending='';
      this.total_messages=''

    }
    

  }

  


  async presentProfilePopup(ev: any) {
    const popover = await this.popoverController.create(
      { component: ProfilePopupComponent, event: ev, translucent: true });
    return await popover.present();
  }

  async presentAdminPopup(ev: any) {
    const popover = await this.popoverController.create(
      { component: AdminPopupComponent, event: ev, translucent: true });
    return await popover.present();
  }
}
