import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ResolveTheme } from 'src/app/services/theme.resolver';

import { ErrorComponent } from './error.component';

@Injectable({ providedIn: 'root' })
export class ErrorResolve implements Resolve<string> {
  constructor() { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    console.log(route.queryParams);
    return of({
      errorMessage: route.queryParams.error,
      errorTranslate: route.queryParams.errorTranslate
    });
  }
}
export const errorRoute: Routes = [
  {
    path: 'error',
    component: ErrorComponent,
    resolve: {
      error: ErrorResolve,
      theme: ResolveTheme
    }
  },
  {
    path: 'accessdenied',
    component: ErrorComponent,
    resolve: { theme: ResolveTheme },
    data: {
      error403: true
    }
  },
  {
    path: '404',
    resolve: { theme: ResolveTheme },
    component: ErrorComponent,
    data: {
      error404: true
    }
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];
