import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {DeleteUserConfirmComponent} from './delete-user-confirm.component';



@NgModule({
  declarations: [DeleteUserConfirmComponent],
  exports: [DeleteUserConfirmComponent],
  imports:
      [CommonModule, IonicModule, TranslateModule.forChild(), FormsModule]
})
export class DeleteUserConfirmModule {
}
