import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { IUser, User } from '../models/user.model';
import { SettingsService } from './settings.service';
import { ApiService } from './api.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUser: Observable<User>;
    private currentUserSubject: BehaviorSubject<User>;

    constructor(
        private settingsService: SettingsService,
        private apiService: ApiService,
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(settingsService.getUser());
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        console.log('.getValue()')
        console.log(this.currentUserSubject.getValue())
        return this.currentUserSubject.getValue();
    }

    async login(email: string, password: string): Promise<IUser | null> {
        const response = await this.apiService.login({ email, password }).toPromise();
        if (response && response.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.settingsService.storeToken(response.token);
            const user = await this.apiService.identity().pipe(first()).toPromise();
            this.currentUserSubject.next(user);
            return user;
        } else {
            return null;
        }
    }

    logout() {
        // remove user from local storage to log user out
        this.settingsService.storeToken(null);
        this.currentUserSubject.next(null);
    }

    identity(force?: boolean): Observable<User> {
        if (!force && this.currentUserValue) {
            return of(this.currentUserValue);
        }
        return this.apiService.identity().pipe(tap(x => {
            if (x) {
                const credits = x.credits || '0';
                this.currentUserSubject.next({ ...x, credits });
            }
        }));
    }
    identitySkipIntercptor(): Observable<User> {
        return this.apiService.identity('?skipInterceptor').pipe(tap(x => {
            if (x) {
                const credits = x.credits || '0';
                this.currentUserSubject.next({ ...x, credits });
            }
        }));
    }
}
