import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { LoginVm } from '../models/login-vm';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { ILoginResponse } from '../models/login-response';
import { IUser } from '../models/user.model';
import { Observable, of } from 'rxjs';
import { SettingsService } from './settings.service';
import { Profile } from '../models/profile.model';
import { IService } from '../models/service.model';
import { ILog } from '../models/log.model';

@Injectable({ providedIn: 'root' })
export class ApiService {
  baseUrl = environment.baseUrl;
  constructor(
    protected http: HttpClient,
    private settingsSetting: SettingsService,
  ) { }
  login(loginVM: LoginVm) {
    const formData = new FormData();
    formData.append('email', loginVM.email);
    formData.append('password', loginVM.password);
    const url = `${this.baseUrl}/login`;
    return this.http.post<ILoginResponse>(url, formData, { observe: 'response' })
      .pipe(map(x => x.body));
  }
  signup(email:string, password:string, privacy:string, terms:string) {
    const url = `${this.baseUrl}/signup`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('privacy', privacy);
    formData.append('terms', terms);
    return this.http.post<string>(url, formData, { observe: 'response' })
      .pipe(map(x => x.body));
  }
  
  // signup(loginVM: LoginVm) {
  //   const url = `${this.baseUrl}/signup`;
  //   const formData = new FormData();
  //   formData.append('email', loginVM.email);
  //   formData.append('password', loginVM.password);
  //   formData.append('privacy', loginVM.privacy);
  //   formData.append('terms', loginVM.terms);
  //   return this.http.post<string>(url, formData, { observe: 'response' })
  //     .pipe(map(x => x.body));
  // }
  verifyCode(userId: string, code: string) {
    const url = `${this.baseUrl}/verify_code/${userId}/${code}`;
    return this.http.get<any>(url, { observe: 'response' })
      .pipe(map(x => x.body));
  }


  reset_pwd_1(email: string) {
    const url = `${this.baseUrl}/reset_pwd_1`;
    const formData = new FormData();
    formData.append('email', email);
    return this.http.post<any>(url, formData, { observe: 'response' })
      .pipe(
        map(x => x.body.ok?.key)
      );
    }

  reset_pwd_2(userId: string, code: string) {
    const url = `${this.baseUrl}/reset_pwd_2/${userId}/${code}`;
    return this.http.get<any>(url, { observe: 'response' })
      .pipe(map(x => x.body));
  }

//reset_pwd_2/6/TFETTZ9H1JF00VV

  subscribe() {
    const url = `${this.baseUrl}/subscribe/${environment.paypal.product}`;
    return this.http.post<any>(url, { observe: 'response' });
    // .pipe(map(x => x.body));
  }
  getProfile() {
    const url = `${this.baseUrl}/getuserprofile`;
    return this.http.get<any>(url, { observe: 'response' })
      .pipe(map(x => x.body));
  }
  getServices(): Observable<IService[]> {
    const url = `${this.baseUrl}/get_services`;
    return this.http.get<any>(url, { observe: 'response' })
      .pipe(
        map(x => x.body as IService[])
      );
  }
  getSubscription(): Observable<any> {
    const url = `${this.baseUrl}/verify_user_subscription/`;
    return this.http.post<any>(url, {}, { observe: 'response' })
      .pipe(
        map(x => x.body.ok.result)
      );
  }
  unsubscribe(): Observable<any[]> {
    const url = `${this.baseUrl}/unsubscribe/`;
    return this.http.post<any>(url, {}, { observe: 'response' })
      .pipe(
        map(x => x.body.ok?.key)
      );
  }

  buycredits(offer_type): Observable<any[]> {
    const url = `${this.baseUrl}/buycredits`;
    const formData = new FormData();
    formData.append('offer_type', offer_type);
    return this.http.post<any>(url, formData, { observe: 'response' })
      .pipe(
        map(x => x.body.ok?.key)
      );
  }


  uploadFile(url: string, file: any, profile: IUser, serviceId: string) {
    const formData = new FormData();
    formData.append('api_key', profile.api_key);
    formData.append('secret_key', profile.secret_key);
    formData.append('id', profile.id);
    formData.append('id_service', serviceId);
    formData.append('pdf', file);
    return this.http.post<any>(url, formData, { observe: 'response' })
      .pipe(map(x => x.body));

  }

  uploadFileDeduplicate(url: string, file: any, profile: IUser, serviceId: string, threshold:any, stopwords:any) {
    const formData = new FormData();
    formData.append('api_key', profile.api_key);
    formData.append('secret_key', profile.secret_key);
    formData.append('id', profile.id);
    formData.append('id_service', serviceId);
    formData.append('threshold', threshold)
    formData.append('stopwords', stopwords)
    formData.append('pdf', file);
    return this.http.post<any>(url, formData, { observe: 'response' })
      .pipe(map(x => x.body));

  }

  uploadFileLDA(url: string, file: any, profile: IUser, serviceId: string, stopwords:any) {
    const formData = new FormData();
    formData.append('api_key', profile.api_key);
    formData.append('secret_key', profile.secret_key);
    formData.append('id', profile.id);
    formData.append('id_service', serviceId);
    
    formData.append('stopwords', stopwords)
    formData.append('pdf', file);
    return this.http.post<any>(url, formData, { observe: 'response' })
      .pipe(map(x => x.body));

  }

  uploadText(url: string, text: any, profile: IUser, serviceId: string) {
    const formData = new FormData();
    formData.append('api_key', profile.api_key);
    formData.append('secret_key', profile.secret_key);
    formData.append('id', profile.id);
    formData.append('id_service', serviceId);
    formData.append('text', text);
    return this.http.post<any>(url, formData, { observe: 'response' })
      .pipe(map(x => x.body));

  }
  uploadgeo(url: string, profile: IUser, serviceId: string, indirizzo:string, stato_OMI:string, tipo_OMI:string, livello_OMI:string) {
    const formData = new FormData();
    formData.append('api_key', profile.api_key);
    formData.append('secret_key', profile.secret_key);
    formData.append('id', profile.id);
    formData.append('id_service', serviceId);
    formData.append('indirizzo', indirizzo);
    formData.append('stato', stato_OMI);
    formData.append('tipo_OMI', tipo_OMI);
    formData.append('livello', livello_OMI);
    return this.http.post<any>(url, formData, { observe: 'response' })
      .pipe(map(x => x.body));

  }

  upload_emailguess(url: string, profile: IUser, serviceId: string, nome:string, cognome:string, azienda:string) {
    const formData = new FormData();
    formData.append('api_key', profile.api_key);
    formData.append('secret_key', profile.secret_key);
    formData.append('id', profile.id);
    formData.append('id_service', serviceId);
    formData.append('nome', nome);
    formData.append('cognome', cognome);
    formData.append('azienda', azienda);
    return this.http.post<any>(url, formData, { observe: 'response' })
      .pipe(map(x => x.body));

  }

  upload_checkVAT(url: string, profile: IUser, serviceId,country: string, vat:string) {
    const formData = new FormData();
    formData.append('api_key', profile.api_key);
    formData.append('secret_key', profile.secret_key);
    formData.append('id', profile.id);
    formData.append('id_service', serviceId);
    formData.append('country', country);
    formData.append('vat', vat);
    
    return this.http.post<any>(url, formData, { observe: 'response' })
      .pipe(map(x => x.body));

  }

  upload_checklatestrates(url: string, profile: IUser, serviceId, val_riferimento: string, val_richiesta:string) {
    const formData = new FormData();
    console.log('richiesta ' ,val_riferimento, val_richiesta )
    formData.append('api_key', profile.api_key);
    formData.append('secret_key', profile.secret_key);
    formData.append('id', profile.id);
    formData.append('id_service', serviceId);
    formData.append('valuta_riferimento', val_riferimento);
    formData.append('valuta_richiesta', val_richiesta);
    
    return this.http.post<any>(url, formData, { observe: 'response' })
      .pipe(map(x => x.body));

  }

  upload_geocoding(url: string, profile: IUser, serviceId, lat,lon,indirizzo,reverse) {
    const formData = new FormData();
    formData.append('api_key', profile.api_key);
    formData.append('secret_key', profile.secret_key);
    formData.append('id', profile.id);
    formData.append('id_service', serviceId);
    formData.append('lat', lat);
    formData.append('lon', lon);
    formData.append('indirizzo', indirizzo);
    formData.append('reverse', reverse);
    
    return this.http.post<any>(url, formData, { observe: 'response' })
      .pipe(map(x => x.body));

  }

  upload_forecast(url: string, profile: IUser, serviceId, file: any, num_periods, cycles) {
    const formData = new FormData();
    formData.append('api_key', profile.api_key);
    formData.append('secret_key', profile.secret_key);
    formData.append('id', profile.id);
    formData.append('id_service', serviceId);
    formData.append('input', file);
    formData.append('n_periods', num_periods);
    formData.append('cycles', cycles)
  
    return this.http.post<any>(url, formData, { observe: 'response' })
      .pipe(map(x => x.body));

  }





  donwloadCSV(json: string) {
    const formData = new FormData();
    const url = `${this.baseUrl}/json_to_csv`;
    formData.append('json', json);
    return this.http.post<any>(url, formData, { observe: 'response', responseType: 'blob' as 'json' })
      .pipe(map(x => x.body));

  }
  donwloadXLSX(json: string) {
    const formData = new FormData();
    const url = `${this.baseUrl}/json_to_xlsx`;
    formData.append('json', json);
    return this.http.post<any>(url, formData, { observe: 'response', responseType: 'blob' as 'json' })
      .pipe(map(x => x.body));

  }
  updateProfile(profile: Profile) {
    const url = `${this.baseUrl}/updateuserprofile`;
    const formData = new FormData();
    formData.append('city', profile.city || '');
    formData.append('company', profile.company || '');
    formData.append('name', profile.name || '');
    formData.append('phone', profile.phone || '');
    formData.append('prov', profile.prov || '');
    formData.append('sdi_code', profile.sdi_code || '');
    formData.append('surname', profile.surname || '');
    formData.append('vatnumb', profile.vatnumb || '');
    formData.append('zipcode', profile.zipcode || '');
    formData.append('address', profile.address || '');

    return this.http.post<any>(url, formData, { observe: 'response' })
      .pipe(map(x => x.body));
  }
  identity(skipAuthInterptor?: string): Observable<IUser> {
    if (this.settingsSetting.getToken()) {
      const url = `${this.baseUrl}/getuserprofile${skipAuthInterptor || ''}`;
      return this.http.get<IUser>(url, { observe: 'response' })
        .pipe(map(x => x.body));
    } else {
      return of(null);
    }
  }


  //ADMIN FUNCTIONS

  getAnalytics(): Observable<any> {
    const url = `${this.baseUrl}/analytics`;
    return this.http.get<any>(url, { observe: 'response' })
      .pipe(
        map(x => x.body.result)
      );
  }

  getPending(): Observable<any> {
    const url = `${this.baseUrl}/approval_pending`;
    return this.http.get<any>(url, { observe: 'response' })
      .pipe(
        map(x => x.body.result)
      );
  }

  getPending_total(): Observable<any> {
    const url = `${this.baseUrl}/approval_pending_total`;
    // console.log('sta per prendersi il totale pending');
    return this.http.get<any>(url, { observe: 'response' })
      .pipe(
        map(x => x.body)
        
      );
      
  }

  

  authorize(choices) {
    const url = `${this.baseUrl}/approve_pending`;
    const formData = new FormData();
    formData.append('json', JSON.stringify({ choices }) || '');
    
    return this.http.post<any>(url, formData, { observe: 'response' })
      .pipe(map(x => x.body));
  }


  getSubscribers(): Observable<any> {
    const url = `${this.baseUrl}/get_subscriber`;
    return this.http.get<any>(url, { observe: 'response' })
      .pipe(
        map(x => x.body.result)
      );
  }

  resendemail(email) {
    const url = `${this.baseUrl}/resend_email`;
    // console.log('entrato nella funzione api url:' + url + ' email ' + email)

    const formData = new FormData();
    formData.append('email', email || '');
    return this.http.post<any>(url, formData, { observe: 'response' })
    .pipe(map(x => x.body))
    
    
} 
  change_pwd(password_old, password_new) {
    const url = `${this.baseUrl}/change_pwd`;
    // console.log('entrato nella funzione api url:' + url + ' email ' + email)

    const formData = new FormData();
    formData.append('password_old', password_old || '');
    formData.append('password_new', password_new || '');
    return this.http.post<any>(url, formData, { observe: 'response' })
    .pipe(map(x => x.body))
    
    
} 
//MESSAGGI
post_message(tipo,testo) {

  const url = `${this.baseUrl}/post_message`;
  const formData = new FormData();
  formData.append('tipo', tipo || '');
  formData.append('testo', testo || '');

  return this.http.post<any>(url, formData, { observe: 'response' })
  .pipe(map(x => x.body))


}

get_messages(): Observable<any> {
  const url = `${this.baseUrl}/get_messages`;
  // console.log('sta per prendersi il totale pending');
  return this.http.get<any>(url, { observe: 'response' })
    .pipe(
      map(x => x.body)
      
    );
    
}

set_message_read(id_msg){
  const url = `${this.baseUrl}/set_message_read`;
  const formData = new FormData();
  formData.append('id_msg', id_msg || '');

  return this.http.post<any>(url, formData, { observe: 'response' })
  .pipe(map(x => x.body))



}
delete_message(id_msg){
  const url = `${this.baseUrl}/delete_message`;
  const formData = new FormData();
  formData.append('id_msg', id_msg || '');

  return this.http.post<any>(url, formData, { observe: 'response' })
  .pipe(map(x => x.body))

}
//MESSAGGI



//FAKER
faker_post(url: string, action: any, quantity: any, sex:string, fromdate:any, todate:any, numdates:any, profile: IUser, serviceId: string) {
  const formData = new FormData();
  formData.append('api_key', profile.api_key);
  formData.append('secret_key', profile.secret_key);
  formData.append('id', profile.id);
  formData.append('id_service', serviceId);
  formData.append('action', action);
  formData.append('quantity', quantity);
  formData.append('sex', sex);
  formData.append('fromdate', fromdate);
  formData.append('todate', todate);
  formData.append('numdates', numdates);
  
  return this.http.post<any>(url, formData, { observe: 'response' })
    .pipe(map(x => x.body));

}


//FAKER


//CAP
get_comuni(url: string, profile: IUser, serviceId: string, stringa_ricerca: string) {
  const formData = new FormData();
  formData.append('api_key', profile.api_key);
  formData.append('secret_key', profile.secret_key);
  formData.append('id', profile.id);
  formData.append('id_service', serviceId);
  formData.append('action', '3');
  formData.append('stringa_ricerca', stringa_ricerca);
  
  
  return this.http.post<any>(url, formData, { observe: 'response' })
    .pipe(map(x => x.body));

}

get_cap_comuni(url: string, profile: IUser, serviceId: string, action:string, CAP: string, Comune: string) {
  const formData = new FormData();
  formData.append('api_key', profile.api_key);
  formData.append('secret_key', profile.secret_key);
  formData.append('id', profile.id);
  formData.append('id_service', serviceId);
  formData.append('action', action);
  formData.append('cap', CAP);
  formData.append('comune', Comune);
  
  
  return this.http.post<any>(url, formData, { observe: 'response' })
    .pipe(map(x => x.body));

}



//CAP


//FEEDBACK_USER
send_feedback(id_service: any, fbk_value: any) {
  const url = `${this.baseUrl}/post_feedback`;
  const formData = new FormData();
  formData.append('id_service', id_service);
  formData.append('fbk_value', fbk_value);  
  return this.http.post<any>(url, formData, { observe: 'response' })
    .pipe(map(x => x.body));

}


//FEEDBACK_USER




  }
