import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Injectable({ providedIn: 'root' })
export class HandlerErrorService {
  constructor(protected translateService: TranslateService) { }

  async handleMessageError(err?: any) {
    let retVal;
    console.log(err);
    if (err.error?.error?.key) {
      const msg = err.error?.error?.key;
      const provisional = await this.translateService.get(msg).toPromise();
      if (provisional === msg) {
        retVal = err.error?.error?.message;
      } else {
        retVal = provisional;
      }
    }
    else if (err.error?.error?.message) {
      retVal = err.error?.error?.message;
    }
    return retVal || err;
  }

}