import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HandlerErrorService } from 'src/app/services/error-handler.service';
import { ApiService } from './api.service';
import { AuthInterceptor } from './auth-interceptor';
import { AuthGuard } from './auth.guard';
import { AuthenticatedErrorHttpService } from './authenticated-error.interceptor';
import { AuthenticationService } from './authentication.service';
import { DarkThemeService } from './dark-theme.service';

import { SettingsService } from './settings.service';

import { ToastService } from './toast.service';


@NgModule({
  providers: [
    SettingsService,
    ToastService,
    ApiService,
    AuthenticationService,
    AuthGuard,
    AuthInterceptor,
    AuthenticatedErrorHttpService,
    DarkThemeService,
    HandlerErrorService
  ],
  imports: [CommonModule]
})
export class AppServiceModule {
}
