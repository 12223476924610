import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

import {ProfilePopupComponent} from './profile-popup.component';



@NgModule({
  declarations: [ProfilePopupComponent],
  imports:
      [CommonModule, IonicModule, TranslateModule.forChild(), RouterModule]
})
export class ProfilePopupModule {
}
