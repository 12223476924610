// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  // baseUrl: 'http://127.0.0.1:5000', //'https://las-backend.qi6qv1rnt3rvo.eu-central-1.cs.amazonlightsail.com' ,//'http://127.0.0.1:5000/', // https://las-backend.qi6qv1rnt3rvo.eu-central-1.cs.amazonlightsail.com',
  baseUrl: 'https://las-backend.qi6qv1rnt3rvo.eu-central-1.cs.amazonlightsail.com' ,//'http://127.0.0.1:5000/', // https://las-backend.qi6qv1rnt3rvo.eu-central-1.cs.amazonlightsail.com',
  paypal: {
    product: 'P-0C0491168G2433403MEOQR3Q'
  },
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
