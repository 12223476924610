import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular/';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(private toastController: ToastController) { }
  async makeToastInvitation() {
    const toast = await this.toastController.create({
      message: 'message',
      position: 'bottom',
      color: 'primary',
      animated: true,
      keyboardClose: true,
      buttons: [{ side: 'end', role: 'cancel', icon: 'close-outline' }]
    });
    toast.present();
  }

  async makeToast(message: string, color: string, position?: 'bottom' | 'top' | 'middle') {
    const toast = await this.toastController.create({
      message,
      duration: 5000,
      position: position || 'top',
      buttons: [{ side: 'end', role: 'cancel', icon: 'close-outline' }],
      color,
      animated: true,
      keyboardClose: true
    });
    toast.present();
  }
  async makeToastSuccess(message) {
    const toast = await this.toastController.create({
      message,
      duration: 5000,
      position: 'top',
      buttons: [{ side: 'end', role: 'cancel', icon: 'close-outline' }],
      color: 'success',
      animated: true,
      keyboardClose: true
    });
    toast.present();
  }

  async makeToastError(message) {
    const toast = await this.toastController.create({
      message,
      duration: 5000,
      position: 'top',
      buttons: [{ side: 'end', role: 'cancel', icon: 'close-outline' }],
      color: 'danger',
      animated: true,
      keyboardClose: true
    });
    toast.present();
  }
}