import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {CookieLawComponent} from './cookie-law.component';



@NgModule({
  declarations: [CookieLawComponent],
  exports: [CookieLawComponent],
  imports: [CommonModule, IonicModule, TranslateModule]
})
export class CookieLawModule {
}
