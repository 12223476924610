import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

import {ErrorComponent} from './error.component';


@NgModule({
  imports: [
    CommonModule, FormsModule, IonicModule, TranslateModule

  ],
  declarations: [ErrorComponent]
})
export class ErrorPageModule {
}
