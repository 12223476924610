import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';

import { AdminPopupComponent } from './admin-popup.component';

@NgModule({
  declarations: [AdminPopupComponent],
  imports:
  [CommonModule, IonicModule, TranslateModule.forChild(), RouterModule]
})

export class AdminPopupModule { }
