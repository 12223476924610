import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { IUser } from '../models/user.model';
import { DarkThemeStatus } from './dark-theme.service';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  constructor(
    private $sessionStorage: SessionStorageService,
    private $localStorage: LocalStorageService) { }

  storeLang(lang: string) { this.$localStorage.store('lang', lang); }

  getLang() { return this.$localStorage.retrieve('lang'); }

  acceptCookieLow() { this.$localStorage.store('cookie-law', true); }

  getCookieLow() { return this.$localStorage.retrieve('cookie-law') || false; }

  storeLastUrl(url: string) { this.$sessionStorage.store('last-url', url); }

  getLastUr() { return this.$sessionStorage.retrieve('last-url') || '/public/home'; }

  storeDarkTheme(darkTheme: boolean) {
    this.$localStorage.store(
      'dark-theme', darkTheme ? DarkThemeStatus.USE_DARK_THEME :
      DarkThemeStatus.DO_NOT_USE);
  }

  getDarkTheme() { return this.$localStorage.retrieve('dark-theme'); }

  storeUser(user: IUser | null) {
    if (user) {
      const s = JSON.stringify(user);
      this.$localStorage.store('user', s);
    } else {
      this.$localStorage.clear('user');
    }
  }

  getUser(): IUser | null {
    const s = this.$localStorage.retrieve('user');
    if (s) {
      return JSON.parse(s);
    } else {
      return null;
    }
  }

  storeToken(tk: string) { this.$localStorage.store('authenticationToken', tk); }

  getToken() { return this.$localStorage.retrieve('authenticationToken'); }

}
