import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication.service';
import {ApiService} from 'src/app/services/api.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-admin-popup',
  templateUrl: './admin-popup.component.html',
  styleUrls: ['./admin-popup.component.scss'],
})
export class AdminPopupComponent implements OnInit {
  pendings$: any;
  total_pending:any;
  role$: Observable<number>;
  role: any = ''
  messages$: any;
  total_messages: any;


  constructor(protected popoverController: PopoverController,
    private apiservice: ApiService,
    private authenticationService: AuthenticationService,) { }

  async ngOnInit() {
    this.role$ = this.authenticationService.currentUser.pipe(map(user => user?.role));
    this.role$.subscribe(val => this.role=val)
    if (this.role==1){
      this.pendings$ = await this.apiservice.getPending_total().toPromise();
      this.total_pending=this.pendings$.result.total_pending
      this.messages$ = await this.apiservice.get_messages().toPromise();
      this.total_messages= this.messages$.meta.total_messages
    }else {
      this.total_pending='';
      this.total_messages=''

    }

  }

  async dismissClick() { await this.popoverController.dismiss(); }

}
