/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';


export const enum DarkThemeStatus {
  USE_DARK_THEME = 'USE_DARK_THEME',
  DO_NOT_USE = 'DO_NOT_USE'
}


@Injectable({ providedIn: 'root' })
export class DarkThemeService {
  darkTheme = false;
  constructor(protected settingService: SettingsService) { }

  applyTheme() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    const settingDarkMode = this.settingService.getDarkTheme();
    // fist check the user preferences if it exists
    if (settingDarkMode) {
      if (settingDarkMode === DarkThemeStatus.USE_DARK_THEME) {
        document.body.classList.add('dark');
        this.darkTheme = true;
      } else if (settingDarkMode === DarkThemeStatus.DO_NOT_USE) {
        document.body.classList.remove('dark');
        this.darkTheme = false;
      }
    } else {
      if (prefersDark.matches && false) {
        document.body.classList.add('dark');
        this.darkTheme = true;
      } else if (settingDarkMode === DarkThemeStatus.DO_NOT_USE) {
        document.body.classList.remove('dark');
        this.darkTheme = false;
      }
    }
  }

  toggleDarkTheme() {
    this.darkTheme = !this.darkTheme;
    document.body.classList.toggle('dark', this.darkTheme);
    this.settingService.storeDarkTheme(this.darkTheme);
  }
  isDarkTheme() { return this.darkTheme; }
}
