/* eslint-disable @angular-eslint/no-input-rename */
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';
import { IUser } from 'src/app/models/user.model';

@Component({
  selector: 'app-delete-user-confirm',
  templateUrl: './delete-user-confirm.component.html',
  styleUrls: ['./delete-user-confirm.component.scss'],
})
export class DeleteUserConfirmComponent implements OnInit, AfterViewInit {
  @Input('user') user: IUser;
  @ViewChild('input') inputElement: IonInput;
  text: string;

  constructor(
    private modalController: ModalController) { }

  async ngOnInit() {
  }
  ngAfterViewInit() {
    setTimeout(() => { this.inputElement.setFocus(); }, 400);
  }
  dismiss() { this.modalController.dismiss({ action: 'cancel' }); }
  delete() { this.modalController.dismiss({ action: 'delete' }); }
}
