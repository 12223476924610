import { registerLocaleData } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from './services/settings.service';
import localeIt from '@angular/common/locales/it';
import localeEnGb from '@angular/common/locales/en-GB';
import { AuthenticationService } from './services/authentication.service';
import { User } from './models/user.model';
import { Router, NavigationEnd } from '@angular/router'; 
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  
  user: User;
  constructor(
    private translate: TranslateService,
    private settingsService: SettingsService,
    private authenticationService: AuthenticationService,
    public router: Router) {

    this.initTranslate();
    // check if logged and trigger observable
    this.authenticationService.identity().subscribe(x => this.user = x);


    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        console.log(event.urlAfterRedirects);
          gtag('config', 'G-RNSM80V9XJ', 
                {
                  'page_path': event.urlAfterRedirects,
                  'page_title': event.urlAfterRedirects
                }
               );
                                        }
                                          }
                                );

    
  }



  initTranslate() {
    const defaultLang = 'it';
    // Set the default language for translation strings as fallback lang
    this.translate.setDefaultLang(defaultLang);
    const settingsLang = this.settingsService.getLang();

    // set current Language
    if (settingsLang !== undefined && settingsLang) {
      this.translate.use(settingsLang);
    } else if (this.translate.getBrowserLang() !== undefined) {
      this.translate.use(this.translate.getBrowserLang());
    } else {
      this.translate.use(defaultLang);  // Set your language here
    }

    registerLocaleData(localeIt);
    registerLocaleData(localeEnGb);
  }

  

  
}
