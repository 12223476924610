/* eslint-disable @angular-eslint/no-input-rename */
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-delete-subscription',
  templateUrl: './delete-subscription.component.html',
  styleUrls: ['./delete-subscription.component.scss'],
})
export class DeleteSubscriptionComponent implements OnInit, AfterViewInit {
  @Input('subscription') subscription: string;
  @ViewChild('input') inputElement: IonInput;
  text: string;
  email: string;

  constructor(private modalController: ModalController, private authenticanService: AuthenticationService) { }

  async ngOnInit() {
    this.email = (await this.authenticanService.identity().toPromise()).email;
  }
  ngAfterViewInit() {
    setTimeout(() => { this.inputElement.setFocus(); }, 400);
  }
  dismiss() { this.modalController.dismiss({ action: 'cancel' }); }
  delete() { this.modalController.dismiss({ action: 'delete' }); }
}
