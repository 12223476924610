import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppServiceModule } from './services/app-service.module';
import { AuthenticatedErrorHttpService } from './services/authenticated-error.interceptor';
import { AuthInterceptor } from './services/auth-interceptor';
import { AppComponentsModule } from './components/app-components.module';
import { ErrorPageModule } from './pages/public/error/error.module';
import { NgxPaginationModule } from 'ngx-pagination';
import {enableProdMode} from '@angular/core';
enableProdMode();


export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot({ prefix: 'las', separator: '-' }),
    TranslateModule.forRoot({
      defaultLanguage: 'it',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AppServiceModule,
    AppComponentsModule,
    ErrorPageModule, NgxPaginationModule, 
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: AuthenticatedErrorHttpService, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
