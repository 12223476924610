import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';


@Injectable({ providedIn: 'root' })
export class AuthenticatedErrorHttpService implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next
            .handle(request)
            .pipe(
                catchError(err => {
                    if (request.urlWithParams.indexOf('skipInterceptor') !== -1) {
                    } else if ([401, 403].indexOf(err.status) !== -1) {
                        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                        this.authenticationService.logout();
                        window.location.href = window.location.href + '?' + new Date().getMilliseconds();
                    }

                    const error = err.error.message || err.statusText;
                    return throwError(error);
                }));
    }
}
