import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { SidebarMenuComponent } from './sidebar-menu.component';



@NgModule({
  declarations: [SidebarMenuComponent],
  exports: [SidebarMenuComponent],
  imports: [
    CommonModule, IonicModule, RouterModule, FormsModule,
    TranslateModule.forChild()
  ]
})
export class SidebarMenuModule {
}
