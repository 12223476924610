import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { IUser, User } from 'src/app/models/user.model';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-profile-popup',
  templateUrl: './profile-popup.component.html',
  styleUrls: ['./profile-popup.component.scss'],
})
export class ProfilePopupComponent implements OnInit {
  user$: Observable<IUser>;
  constructor(
    private authenticationService: AuthenticationService,
    protected popoverController: PopoverController,
    private router: Router) { }

  ngOnInit() {
    this.user$ = this.authenticationService.identity();
  }
  async dismissClick() { await this.popoverController.dismiss(); }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/']);
    this.dismissClick();
  }
}
