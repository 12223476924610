import { Component, OnInit } from '@angular/core';
import data from '../../../../package.json';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  version=data.version;

  list = [{
    title: 'footer.about.us',
    url: '/public/about-us'
  },
  {
    title: 'footer.api.docs',
    url: '/public/api-docs'
  }, {
    title: 'sidebar.settings',
    subTitle: 'setting.desc',
    url: '/public/settings'
  }
  ];
  list2 = [
    {
      title: 'footer.buttons.link2',
      url: '/public/terms'
    },
    {
      title: 'footer.buttons.link3',
      url: '/public/privacy'
    }
  ];


  constructor() { }

  ngOnInit() { }

}
