import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CookieLawModule } from './cookie-law/cookie-law.module';
import { DeleteSubscriptionModule } from './delete-subscription/delete-subscription.module';
import { DeleteUserConfirmModule } from './delete-user-confirm/delete-user-confirm.module';
import { FooterModule } from './footer/footer.module';
// import {AdminPopupModule} from './admin-popup/admin-popup.module';
import { ProfilePopupModule } from './profile-popup/profile-popup.module';
import { SidebarMenuModule } from './sidebar-menu/sidebar-menu.module';
import { ToolbarModule } from './toolbar/toolbar.module';

@NgModule({
  declarations: [],
  imports: [
    ProfilePopupModule,
    ToolbarModule,
    CommonModule,
    ProfilePopupModule,
    // AdminPopupModule,
    DeleteUserConfirmModule,
    CookieLawModule,
    DeleteSubscriptionModule,
    SidebarMenuModule,
    FooterModule
  ],
  exports: [
    ProfilePopupModule,
    ToolbarModule,
    ProfilePopupModule,
    // AdminPopupModule,
    DeleteUserConfirmModule,
    CookieLawModule,
    DeleteSubscriptionModule,
    SidebarMenuModule,
    FooterModule
  ]
})
export class AppComponentsModule {
}
